import AppLayout from "layouts/AppLayout";
import AppRoutes from "layouts/AppRoutes";
import React from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";

export const DataChangeHook = React.createContext();

function App() {
  const [chg, setChg] = React.useState(100);
  const changeData = {
    chg,
    setChg
  };
  return (
    <React.Fragment><DataChangeHook.Provider value={changeData}>
      <AppLayout>
        <AppRoutes />
      </AppLayout>
      <ToastContainer /></DataChangeHook.Provider>
    </React.Fragment>
  );
}

export default App;
